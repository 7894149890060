<div class="bc-row bc-justify-content-center">
    <div class="bc-col-md-10 bc-col-xs-5">
        <h6 class="title">
            Por favor ingresa la siguiente información, así consultaremos el valor de la cuota de tu crédito.
        </h6>
    </div>
</div>
<br>

<div class="bc-row bc-justify-content-center">
    <div class="bc-col-md-10 bc-col-xs-5">
        <div id="alertErrorDiv"></div>
    </div>
</div>

<br>

<!-- <form [formGroup]="formStepOne">
    <div class="bc-row bc-justify-content-center">
        <div class="bc-col-md-8 bc-col-xs-5">
            <label>Elige el tipo de documento</label>
            <bc-input-select formatType="simple" [values]="optionsSelect"
                            span="*Por favor selecciona tu tipo de documento de identidad"
                            formControlName="documentType"
                            id="documentType"
                            status="{{ stateDocType }}">
            </bc-input-select>
        </div>
    </div>
    <br>
    <div class="bc-row bc-justify-content-center">
        <div class="bc-col-md-8 bc-col-xs-5">
            <bc-form-field>
                <label>Ingresa el número del documento</label>
                <input bc-input
                        aria-label="input"
                        autocomplete="off"
                        type="text" value=""
                        formControlName="documentNumber"
                        state="{{ stateDocNum }}"
                        id="documentNumber"
                        mask="99999999999" [specialCharacters]="[]"
                        #inputId
                        required/>
                <span>*Digita el número de tu documento de identidad</span>
            </bc-form-field>
        </div>
    </div>
    <br>
    <div class="bc-row bc-justify-content-center">
        <div class="bc-col-md-8 bc-col-xs-5">
            <bc-form-field id="paymentReferenceField">
                <div class="bc-row bc-align-items-center bc-col" style="width: 100%;">
                    <label>Ingresa el número de referencia de pago</label>
                </div>
                <input bc-input
                        aria-label="input"
                        autocomplete="off"
                        type="text"
                        (click)="openAlertReferenciaPago()"
                        id="paymentReference"
                        formControlName="paymentReference"
                        state="{{ statePayRef }}"
                        mask="99999999999" [specialCharacters]="[]"
                        #inputPayRef
                        required/>
                <span>*Digita el número de tu referencia de pago</span>
            </bc-form-field>
        </div>
    </div>
    <re-captcha #invisibleCaptcha size="invisible" siteKey="{{keyCaptcha}}"></re-captcha>
</form> -->

<div class="bc-row bc-justify-content-center">
    <div class="bc-col-md-8 bc-col-xs-5">
        <form [formGroup]="formStepOne">
            <section>
                <bc-input-select-v2
                        id="documentType"
                        type="simple"
                        label="Elige el tipo de documento"
                        helpText="*Por favor selecciona tu tipo de documento de identidad"
                        [values]="optionsSelect"
                        formControlName="documentType"
                      >
                </bc-input-select-v2>
            </section>
            <br>
            <section>
                <bc-form-field id="documentNumberField">
                    <input bc-input
                            aria-label="input"
                            autocomplete="off"
                            type="text" value=""
                            formControlName="documentNumber"
                            state="{{ stateDocNum }}"
                            id="documentNumber"
                            mask="99999999999" [specialCharacters]="[]"
                            #inputId
                            required/>
                    <label for="documentNumber">Ingresa el número del documento</label>
                    <span>*Digita el número de tu documento de identidad</span>
                </bc-form-field>
            </section>
            <br>
            <section>
                <bc-form-field id="paymentReferenceField">
                    <!-- <div class="bc-row bc-align-items-center bc-col" style="width: 100%;">
                        <label>Ingresa el número de referencia de pago</label>
                    </div> -->
                    <input bc-input
                            aria-label="input"
                            autocomplete="off"
                            type="text"
                            (click)="openAlertReferenciaPago()"
                            id="paymentReference"
                            formControlName="paymentReference"
                            state="{{ statePayRef }}"
                            mask="99999999999" [specialCharacters]="[]"
                            #inputPayRef
                            required/>
                    <label for="paymentReference">Ingresa el número de referencia de pago</label>
                    <span>*Digita el número de tu referencia de pago</span>
                </bc-form-field>
            </section>
            <re-captcha #invisibleCaptcha size="invisible" siteKey="{{keyCaptcha}}"></re-captcha>
        </form>
    </div>
</div>


<br><br>