import { CheckoutWompiGateway } from "src/app/domain/models/gateways/checkout-wompi-gateway";
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { ResponseCustomer } from "src/app/domain/models/response-customer";


declare let WidgetCheckout: any;
@Injectable({
    providedIn: 'root'
})


export class CheckoutWompiAdapter implements CheckoutWompiGateway {
  // widget wompi
  checkout: any;
  keyWompi: any;
  redirectUrl: any;

  constructor() {
    this.keyWompi = environment.wompiKey;
    this.redirectUrl = environment.redirectUrlWompi;
  }


  checkoutWidget(amount: number, responseCustomer: ResponseCustomer): void {
    const Widget = this.getInstance();
    const num100 = 100;

    if (responseCustomer.email == null){
      responseCustomer.email = " ";
    }

    if (responseCustomer.phone == null){
      responseCustomer.phone = " ";
    }

    if (responseCustomer.name == null){
      responseCustomer.name = " ";
    }

    this.checkout =  new Widget({
      currency: 'COP',
      amountInCents: Math.floor(amount * num100),
      reference: responseCustomer.idPaymentWompi,
      publicKey: this.keyWompi,
      redirectUrl: this.redirectUrl,      
      customerData: { // Opcional
        email: responseCustomer.email,
        fullName: responseCustomer.name,
        phoneNumber: responseCustomer.phone,
        phoneNumberPrefix: '+57'
      }
    });

    this.checkout.open(function (result:any) {
      const transaction = result.transaction;
      let url = transaction.redirectUrl;
      let id = transaction.id;
      window.location.href = url + "?id=" + id;
    });
  }

  getInstance(){
    return WidgetCheckout;
  }
}
