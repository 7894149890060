<div class="bc-row bc-justify-content-center">
  <div class="bc-col-md-10 bc-col-sm-12 bc-col-xs-6">
      <h6 class="title">
        Selecciona el valor que vas a pagar:
      </h6>
  </div>
</div>

<br>
<form [formGroup]="frmStepTwo">
<div class="bc-row bc-justify-content-center bc-align-items-center">
  <div class="bc-col-lg-4 bc-col-md-5 bc-col-sm-10 bc-col-xs-5">
    <div class="card" id="card">
      <div class="card-header">
        <div class="bc-row bc-justify-content-center bc-align-self-center">
          <div class="bc-w-auto bc-align-items-center">
            <div class="titulo">Valor a pagar:</div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="bc-row bc-justify-content-center">
          <div class="bc-col-auto">
            <bc-icon size="3xl" name="cash"></bc-icon>
          </div>
        </div>
        <div class="bc-row bc-justify-content-center">
          <div class="bc-col-auto">
            <div class="valor-minimo">{{customer.currentMinimumPayment | currency:"$":"symbol":"1.2-2"}}</div>
            <hr>
          </div>
        </div>
        <div class="bc-row bc-justify-content-center">
          <div class="bc-col-auto">
            <bc-radio-group formControlName="radioMinimun" (change)="changeValue($event)">
              <bc-radio id="pt-min" [enabled]="customer.currentMinimumPayment!=0" value="1">Valor pago mínimo</bc-radio>
            </bc-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bc-col-md-4 bc-col-sm-8 bc-col-xs-5 bc-mt-3">
    <div class="bc-row">
      <div class="bc-col-12">
        <h6 class="titulo2">También puedes seleccionar otro valor a pagar:</h6>
      </div>
    </div>
    <div class="bc-row">
      <div class="bc-col-xs-5 bc-col-sm-12 bc-col-md-11 bc-col-lg-8">
        <bc-radio-group  formControlName="radioPayment" id="radioPayment" (change)="changeValue($event)">
          <bc-radio id="pt-tot" value="2">
            Pago total: {{customer.currentTotalPayment | currency:"$":"symbol":"1.2-2"}}
          </bc-radio>
          <bc-radio id="pt-other" value="3">
            Otro valor
          </bc-radio>
        </bc-radio-group>
      </div>
    </div>
    <br>
    <div class="bc-row">
      <div class="bc-col-md-12 bc-col-sm-12">
        <label>Digita el valor que quieres pagar:</label>
          <bc-form-field>
            <em>currency-peso</em>
            <input bc-input type="text"
                  state="{{stateOtherValue}}" id="otherValue" name="otherValue"
                  autocomplete="off"
                  formControlName="otherValue"
                  (click)="activateOtherValue()"
                  (keyup)="validateOtherValue($event)"
                  #inputOtherValue
                  mask="separator.2">
            <span *ngIf="showErrorOtherValue">
              * Por favor ingresa un valor que no sea superior al pago total.
            </span>
          </bc-form-field>
      </div>
    </div>
  </div>
</div>

<br><br>

<div class="bc-row bc-justify-content-center" style="height: 35px;">
  <div class="bc-col-md-10 bc-col-sm-11 bc-col-xs-5 col-gray">
    <div class="bc-row bc-justify-content-center bc-align-items-center" style="height: 100%;">
      <div class="bc-w-auto">
        <div class="titulo">Detalles del crédito:</div>
      </div>
    </div>
  </div>
</div>
<div class="bc-row bc-justify-content-center">
  <div class="bc-col-md-3 bc-col-sm-10 bc-col-xs-5">
    <bc-form-field>
      <label>Número de crédito:</label>
      <input bc-input aria-label="input" type="text" id=NumeroCredito value="{{ customer.loanId }}" readonly/>
    </bc-form-field>
  </div>
  <div class="bc-col-md-3 bc-col-sm-10 bc-col-xs-5">
    <bc-form-field>
      <label>Tipo de crédito:</label>
      <input bc-input aria-label="input" type="text" id=TipoCredito value="{{ customer.productGroupDescription }}" readonly/>
    </bc-form-field>
  </div>
  <div class="bc-col-md-3 bc-col-sm-10 bc-col-xs-5">
    <bc-form-field >
      <label>Fecha límite de pago:</label>
      <input bc-input type="text" id="FechaLimite" value="{{ dateToPay }}" readonly/>
    </bc-form-field>
  </div>
</div>
</form>

<br><br>

<div class="bc-row bc-justify-content-center">
  <div class="bc-col-md-1 bc-col-sm-2 bc-col-xs-1 text-center">
    <bc-icon size="xl" name="info"></bc-icon>
  </div>
  <div class="bc-col-md-9 bc-col-sm-8 bc-col-xs-5">
    <div class="advertisement">
      Si vas a pagar con PSE ten en cuenta que el horario de servicio en días hábiles es de 6:00 a.m. a 5:00 p.m. Si haces el pago después de este horario, este quedará con la fecha del siguiente día hábil. Si vas a pagar con otras opciones, lo puedes hacer todos los días de 6:00 a.m. a 10:00 p.m. Este pago se verá reflejado en tu crédito al siguiente día hábil.
    </div>
  </div>
</div>
<br>
<div class="bc-row bc-justify-content-center">
  <div class="bc-col-md-1 bc-col-sm-2 bc-col-xs-1 text-center">
    <bc-icon size="xl" name="info"></bc-icon>
  </div>
  <div class="bc-col-md-9 bc-col-sm-8 bc-col-xs-5">
    <div class="advertisement">
      Si realizas el pago total de tu crédito, ten en cuenta que podrían quedar valores generados y no cobrados pendientes por cancelar. Comunícate al 018000517834 para mayor información​.
    </div>
  </div>
</div>

<br><br>

<bc-modal title=""
          componentId="modalToWompi"
          [typeIcon]="typeIcon"
          customWidth="medium"
          [titleAlign]="titleAlign"
          [isCancellable]="action"
          [subTitle]="subTitle"
          buttonAlignment="{{ buttonAlignment }}"
          textBtn="Sí"
          textBtnLeft="No"
          typeNotify="success"
          (stateClick)="onPressButton($event)"
          [displayButton]="displayButton"
          [outerCloseTap]="outerCloseTap">
  <div modalContent>
    <p>
      Estás a punto de abrir una página externa al sitio oficial de Sufi,
      al hacerlo ingresarás a la pasarela de pagos Wompi. ¿Deseas continuar?
    </p>
  </div>
</bc-modal>