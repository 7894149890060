import { Injectable } from '@angular/core';
import { CheckoutWompiGateway } from '../models/gateways/checkout-wompi-gateway';
import { ResponseCustomer } from '../models/response-customer';


@Injectable()

export class CheckoutWompiUseCase {

    constructor(private gateway: CheckoutWompiGateway) { }

    checkoutWidget(amount: number, responseCustomer:ResponseCustomer){
        this.gateway.checkoutWidget(amount, responseCustomer);
    }
}