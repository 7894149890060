<div class="bc-container">
    <br>
    <div class="bc-row bc-justify-content-center">
        <div class="bc-col-auto">
            <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9998 3.99996C17.4598 3.99996 3.99984 17.46 3.99984 34C3.99984 50.54 17.4598 64 33.9998 64C50.5398 64 63.9998 50.54 63.9998 34C63.9998 17.46 50.5398 3.99996 33.9998 3.99996ZM33.9998 67.3333C15.6198 67.3333 0.666504 52.38 0.666504 34C0.666504 15.62 15.6198 0.666626 33.9998 0.666626C52.3798 0.666626 67.3332 15.62 67.3332 34C67.3332 52.38 52.3798 67.3333 33.9998 67.3333ZM31.9718 36.4575V7.48578H35.3046V34.4759L55.5731 46.178L53.9067 49.0643L32.1549 36.4575H31.9718Z" fill="#DD3542"/>
            </svg>
        </div>
    </div>
    <br>
    <div class="bc-row bc-justify-content-center">
        <div class="bc-col-auto">
            <h6><strong>¡Hola!</strong></h6>
        </div>
    </div>
    <div class="bc-row bc-justify-content-center bc-text-center">
        <div class="bc-col-auto">
            <h6><strong>En este momento no es posible realizar el pago de tu crédito en línea.</strong></h6>
        </div>
    </div>
    <div class="bc-row bc-justify-content-center bc-text-center">
        <div class="bc-col-auto">
            <h6><strong>{{ content }}</strong></h6>
        </div>
    </div>
    <br>
    <div class="bc-row bc-justify-content-center">
        <div class="bc-col-auto">
            <a href="https://sufi.grupobancolombia.com/">
                <button bc-button sizeButton="small">REGRESAR AL INICIO</button>
            </a>
        </div>
    </div>
    <br><br>
</div>
