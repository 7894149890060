import { Component, Input,  OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ResponseCustomer } from 'src/app/domain/models/response-customer';
import { CheckoutWompiUseCase } from 'src/app/domain/usecases/checkout-wompi-usecase';


@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {

  @Input() customer: ResponseCustomer;
  @Input() form: UntypedFormGroup;
  @Input() step: any;

  // widget wompi
  showErrorOtherValue:Boolean;
  stepThree:any=3;
  // fin widget

  constructor(private wompi:CheckoutWompiUseCase) { }

  ngOnInit(): void {
    if (this.step === this.stepThree){
      this.openWompi();
    }
  }

  openWompi(){
    let amount: any;
    if(this.form.get('radioMinimun')?.value==='1'){
      amount = this.customer.currentMinimumPayment;
    }
    else if(this.form.get('radioPayment')?.value==='2'){
      amount = this.customer.currentTotalPayment;
    }
    else if (this.form.get('radioPayment')?.value==='3'){
      amount = this.form.get('otherValue')?.value.replace(/^\D+/g, '').replace(',', '').replace(',', '').replace(',', '');;
    }
    this.wompi.checkoutWidget(amount,this.customer);
  }

}
