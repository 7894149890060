import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlockHourGuard implements CanActivate {

  constructor( private router: Router ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

      const sixAM = new Date(environment.dateSixAM);
      const tenPM = new Date(environment.dateTenPM);

      const twentyFour = 24;

      const today = new Date();
      let hour = today.getUTCHours();

      const min = sixAM.getUTCHours();
      const max = tenPM.getUTCHours() + twentyFour;

      //console.log(min, hour, max)

      if(hour < tenPM.getUTCHours()){
        hour = hour + twentyFour;
      }

      if(next.routeConfig?.path != environment.blockHourUrl){
        if(hour < min || hour >= max){
          this.router.navigate([environment.blockHourUrl]);
          return false;
        }
      }
      else if(next.routeConfig?.path === environment.blockHourUrl){
        if(hour >= min && hour < max){
          this.router.navigate(['']);
          return false;
        }
      }
      return true;
    }
}