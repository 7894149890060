import { ReferencePaymentUseCase } from './domain/usecases/paymentLink/referencePayment-usecase';
import { CustomerAdapter } from './infraestructure/driven-adapters/customer-adapter';
import { CustomerUseCase } from './domain/usecases/customer-usecase';
import { BcTooltipModule } from '@bancolombia/design-system-web/bc-tooltip';
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert';
import { StepTwoComponent } from './ui/payment-gateway/step-two/step-two.component';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './ui/shared/header/header.component';
import { FooterComponent } from './ui/shared/footer/footer.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { UnavailabilityScreenComponent } from './ui/payment-gateway/unavailability-screen/unavailability-screen.component';
import { HomeComponent } from './ui/payment-gateway/home/home.component';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcStepperModule } from '@bancolombia/design-system-web/bc-stepper';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { BcInputSelectV2Module } from '@bancolombia/design-system-web/bc-input-select-v2';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import { BcRadioModule } from '@bancolombia/design-system-web/bc-radio';
import { BcModalModule } from '@bancolombia/design-system-web/bc-modal';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcLoaderModule } from '@bancolombia/design-system-web/bc-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StepOneComponent } from './ui/payment-gateway/step-one/step-one.component';
import { CustomerGateway } from './domain/models/gateways/customer-gateway';
import { HttpClientModule } from '@angular/common/http';
import { StepThreeComponent } from './ui/payment-gateway/step-three/step-three.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { LoadingScreenComponent } from './ui/payment-gateway/loading-screen/loading-screen.component';
import { StepFourComponent } from './ui/payment-gateway/step-four/step-four.component';
import { CheckoutWompiUseCase } from './domain/usecases/checkout-wompi-usecase';
import { CheckoutWompiGateway } from './domain/models/gateways/checkout-wompi-gateway';
import { CheckoutWompiAdapter } from './infraestructure/driven-adapters/checkout-wompi-adapters';
import { DatePipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { options } from './domain/models/constants';
import { PaymentLinkComponent } from './ui/payment-link/payment-link.component';
import { ReferencePaymentGateway } from './domain/models/gateways/paymentLink/referencePayment-gateway';
import { ReferencePaymentAdapter } from './infraestructure/driven-adapters/paymentLink/referencePayment-adapter';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UnavailabilityScreenComponent,
    HomeComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    LoadingScreenComponent,
    StepFourComponent,
    PaymentLinkComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbCollapseModule,
    BcButtonModule,
    BcStepperModule,
    BcInputSelectModule,
    BcInputSelectV2Module,
    BcInputModule,
    BcTooltipModule,
    BcAlertModule,
    BcCardModule,
    BcRadioModule,
    BcModalModule,
    BcLoaderModule,
    BcIconModule.forRoot({
      path: "https://library-sdb.apps.bancolombia.com/sufi/1.0.2"
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    NgxMaskModule.forRoot(options)
  ],
  providers: [
    BcDialogService,
    DatePipe,
    CheckoutWompiUseCase,
    CustomerUseCase,
    ReferencePaymentUseCase,
    {
      provide: CustomerGateway,
      useClass: CustomerAdapter
    },
    {
      provide: CheckoutWompiGateway,
      useClass: CheckoutWompiAdapter
    },
    {
      provide: ReferencePaymentGateway,
      useClass: ReferencePaymentAdapter
    },
    {
      provide: 'googleTagManagerId',
      useValue: "GTM-52BS9HC"
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
